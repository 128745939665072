import React from 'react'
import { Iimages, IMessages } from "types"
import IncomingMessage from "../IncomingMessage/IncomingMessage"
import SenderMessages from "../SenderMessages/SenderMessages"
import SystemMessage from '../SystemMessage/SystemMessage'

function MessageBalloon ({ item, creatorId, formatDate, id }: {
  item: IMessages,
  creatorId?: string,
  formatDate: (date: string | Date) => string | undefined,
  id: string
}) {
  if (item.isSystemMessage) {
    return (
      <SystemMessage
        formatDate={formatDate}
        date={item.createdAt}
        message={item.content}
        id={item.senderId}
        user={{ name: 'System' }}
      />
    )
  }

  if (item.sender.uniqueCode && !item.isSystemMessage && (item.sender.uniqueCode === creatorId)) {
    return (
      <>
        <SenderMessages
          id={id}
          formatDate={formatDate}
          message={item.content}
          date={item.createdAt}
          read={item.everybodyHasRead}
          hasFile={item.hasFile}
          file={item.file}
        />

        {item.images && item.images.map((image: Iimages, index: number) =>
          <SenderMessages
            key={image.key}
            id={`${id}image-${index}`}
            formatDate={formatDate}
            date={item.createdAt}
            file={image}
            message=''
            hasFile={true}
            read={item.everybodyHasRead}
          />
        )}
      </>
    )
  }

  return (
    <>
      <IncomingMessage
        id={id}
        formatDate={formatDate}
        date={item.createdAt}
        message={item.content}
        hasFile={item.hasFile}
        isSystemMessage={item.isSystemMessage}
        file={item.file}
        user={{
          avatar: item.sender?.image,
          name: item.sender.username
        }}
      />

      {item.images && item.images.map((image: Iimages, index: number) =>
        <IncomingMessage
          key={image.key}
          id={`${id}image-${index}`}
          formatDate={formatDate}
          date={item.createdAt}
          file={image}
          message=''
          hasFile={true}
          user={{
            avatar: item.sender?.image,
            name: item.sender.username
          }}
        />
      )}
    </>
  )
}

export default MessageBalloon
