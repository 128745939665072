(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"));
	else if(typeof define === 'function' && define.amd)
		define("agx-chat-web", ["react"], factory);
	else if(typeof exports === 'object')
		exports["agx-chat-web"] = factory(require("react"));
	else
		root["agx-chat-web"] = factory(root["react"]);
})(self, (__WEBPACK_EXTERNAL_MODULE_react__) => {
return 