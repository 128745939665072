import React from 'react'
import useTheme from '../../hooks/useThemes'
interface IProps {
  label: string
  onChange: (value: string) => void
  name: string
  placeholder: string,
}

function Textarea ({ name, placeholder, label, onChange }: IProps) {
  const { theme } = useTheme()
  return (
    <div className='text-area'>
      <label htmlFor={name} className="text-area__label"
        style={{ color: theme.newChatFormTexts}} >{label}</label >
      <textarea
        style={{
          backgroundColor: theme.inputBg,
          color: theme.newChatFormTexts
        }}
        name={name}
        placeholder={placeholder}
        className="text-area__input"
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  )
}

export default Textarea
