import dayjs, { ConfigType, Dayjs } from 'dayjs'
import dayjsBR from 'dayjs/locale/pt-br'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isToday from 'dayjs/plugin/isToday'
const serverTz = 'America/Sao_Paulo'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isToday)
dayjs.locale(dayjsBR)

export const timeAsDayjs = (value: ConfigType = new Date(), options?: {server?: boolean, applyTimezone?: boolean}): Dayjs => {
  const server = options?.server
  const applyTimezone = options?.applyTimezone

  let timezone = dayjs.tz.guess()
  if (!(applyTimezone ?? true)) timezone = 'GMT'
  else if (server ?? false) timezone = serverTz

  return dayjs.tz(value, timezone)
}

export const time = (value: ConfigType = new Date(), options?: {server?: boolean, applyTimezone?: boolean}): Date => {
  const server = options?.server
  const applyTimezone = options?.applyTimezone
  let aux: Dayjs

  if (!(applyTimezone ?? true)) aux = dayjs.tz(value, 'GMT') // Set applyTimezone to false when timezone is applied incorrectly (usually when working with JSDate and timestamps)
  else if (server ?? false) aux = dayjs.tz(value, serverTz)
  else aux = dayjs.tz(value, dayjs.tz.guess())

  return new Date(Date.UTC(aux.year(), aux.month(), aux.date(), aux.hour(), aux.minute(), aux.second(), aux.millisecond()))
}

export class SlaDates {
  public seconds: number | null

  constructor (seconds: number) {
    this.seconds = seconds
  }

  private holidays = {
    2023: [
      '09-07',
      '10-12',
      '10-17',
      '11-02',
      '11-15',
      '11-20',
      '12-25'
    ]
  }

  private isWorkingDay (date: Dayjs) {
    const dayOfWeek = date.day()
    return dayOfWeek >= 1 && dayOfWeek <= 5 // Monday to Friday (1 to 5)
  }

  private isWorkingHour (date: Dayjs) {
    const hour = date.hour()
    return hour >= 9 && hour <= 18 // Between 9 AM and 5 PM (inclusive)
  }

  public isWeekendOrHoliday (date: Dayjs | Date | string): boolean {
    const weekDay = timeAsDayjs(date, { applyTimezone: false }).day()
    if ([6, 0].includes(weekDay)) return true

    const formatedDate = timeAsDayjs(date).format('MM-DD')
    const isHollyday = this.holidays[2023].includes(formatedDate)
    return isHollyday
  }

  private isHoliday (date: Dayjs) {
    const formatedDate = timeAsDayjs(date, { applyTimezone: false }).format('MM-DD')
    const isHollyday = this.holidays[2023].includes(formatedDate)
    return isHollyday
  }

  public canUpdateClock (date: Dayjs) {
    const isWorking = this.isWorkingDay(date) && this.isWorkingHour(date)
    return !this.isWeekendOrHoliday(date) && isWorking
  }

  public getDiffInSeconds (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs): number {
    const diffInSeconds = endDate.diff(startDate, 'second')
    return diffInSeconds
  }

  calculateWorkingTime (startDate: Dayjs, endDate: Dayjs, workingHours: { start: number, end: number }) {
    let current = startDate.clone()
    let workingTime = 0

    while (current.isBefore(endDate)) {
      // Skip weekends (Saturday and Sunday) and Holydays

      if (this.isWorkingDay(current) && !this.isHoliday(current)) {
        const startOfDay = current.clone().startOf('day').add(workingHours.start, 'hour')
        const endOfDay = current.clone().startOf('day').add(workingHours.end, 'hour')

        if (current.isBefore(startOfDay)) {
          // If current time is before the start of working hours, move to the start of working hours
          current = startOfDay.clone()
        }

        if (current.isAfter(endOfDay)) {

          current = current.clone().add(1, 'day').startOf('day')

        } else if (endDate.isAfter(endOfDay)) {
          // If end date is after the end of working hours, add a full working day

          workingTime += Math.abs(endOfDay.clone().diff(current, 'second'))
          current = current.clone().add(1, 'day').startOf('day').add(workingHours.start, 'hour')

        }  else {
          // Calculate working time for the last day (end date)
          workingTime += Math.abs(endDate.clone().diff(current, 'second'))

          break
        }
      } else {
        // Move to the next day
        current = current.clone().add(1, 'day').startOf('day')
      }
    }

    return workingTime
  }

  // Function to calculate the difference in seconds between two dates considering working hours
  getDifferenceInSeconds (startDate: Dayjs, endDate: Dayjs, workingHours: { start: number, end: number }) {

    if (endDate.isBefore(startDate)) {
      throw new Error('End date should be after the start date.')
    }

    const workingTimeInSeconds = this.calculateWorkingTime(startDate, endDate, workingHours)
    return workingTimeInSeconds
  }

  static secondsInHours (currentSeconds: number) {
    const hours = Math.floor(currentSeconds / 3600)
    const minutes = Math.floor((currentSeconds % 3600) / 60)
    const seconds = currentSeconds % 60
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
  }

  static getColorsByTime (seconds: number) {
    const hours = seconds / 3600
    if (hours <= 2) {
      return '#00CF7B'
    } 

    if (hours >= 2 && hours < 3) {
      return '#FF9838'
    }   

    if (hours >= 3 && hours < 4) {
      return '#DD4E4E'
    }

    return '#151919'
  }
}
