import { useState } from "react"
import { IMessages, ISocketMessage } from 'types'

export const useMessages = () => {
  const [messages, setMessages] = useState<IMessages[]>([])
  const [totalMessages, setTotalMessages] = useState<number>(0)

  const addMessages = (
    messages: IMessages[]
  ) => {
    setMessages(messages)
  }
  const addTotalMessages = ( total: number) => {
    setTotalMessages(total)
  }

  const incrementMessages = (ticket: ISocketMessage, currentChatId: string) => {
    const existentMessage = messages.find(item => item.messagecode === ticket.message.messagecode)
    if (ticket.message.ticketId === currentChatId && !existentMessage) {
      addMessages([...messages, ticket.message])
    }
  }

  const updateMesssagesAsRead = (ticket: ISocketMessage, currentChatId: string) => {
    if (ticket.markAsRead || currentChatId === ticket?.message?.ticketId) {
      const readMessages = messages.map((message) => ({ ...message, everybodyHasRead: true }))
      addMessages(
        readMessages
      )
    }
  }

  const hasOlderMessages = () => {
    return messages.length > 9 && messages.length < totalMessages
  }

  return {
    addMessages,
    messages,
    incrementMessages,
    updateMesssagesAsRead,
    addTotalMessages,
    hasOlderMessages
  }
}
