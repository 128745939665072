import SearchIcon from '../../icons/SearchIcon'
import React, { useState } from 'react'
import useTheme from '../../hooks/useThemes'

interface IProps {
  searchChat: (value: string, key: string)=> void
  searchKeys:{label: string, value: string}[]
  loadingSearch: boolean
}


function SearchInput ({searchChat, searchKeys, loadingSearch}: IProps) {
  const [searchKey, setSearchKey] = useState<string>(searchKeys[0].value)
  const [searchValue, setSearchValue] = useState<string>('')
  const {theme} = useTheme()

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      searchChat(searchValue, searchKey)
    }
  }

  return (
    <div className="input-search" >
      <select className="input-search__select" onChange={(e)=> setSearchKey(e.target.value)}
        style={{
          color: theme.asideFontColor,
          border: `${theme.asideFontColor} 1px solid`
        }}
      
      >
        {searchKeys.map((item, index)=> 
          <option className="input-search__option" key={index} value={item.value}>
            {item.label}
          </option>
        )}
      </select>

      <input 
        className='input-search__search'
        placeholder='Pesquisar conversa...'
        onChange={onSearch}
        onKeyDown={handleKeyPress}
        style={{
          color: theme.asideFontColor,
          border: `${theme.asideFontColor} 1px solid`,
          borderLeft: 0
        }}
      />

      <button 
        className="input-search__icon" 
        onClick={() => searchChat(searchValue, searchKey)}
      >
        {loadingSearch ? (
          <div className="messenger__messages-loading">
            <div className="messenger__messages-loading--loader" />
          </div>
        ) : <SearchIcon />} 
      </button>
    </div>
  )
}

export default SearchInput
