import React, { createContext, ReactNode } from 'react'

const defaultThemeVariables = {
  asideBg: '#eee',
  asideFontColor: 'white',
  messengerMessagesBg: '#eeeeee',
  headerAndSenderBg: '#232831',
  buttonPrimary: '#25D366',
  buttonPrimaryText: '#707070',
  buttonsDisabled: '#94989D',
  uploadFileIconColor: '#8696a0',
  messengerNotSelectedBg: '#DDDDDD',
  newChatFormBg: '',
  newChatFormRadius: '0  20px 20px 0' ,
  newChatFormTexts: '#000',
  newChatFormDeleteFileButton: '#DD4E4E',
  disclaimerPrimaryColor: '#4791FF',
  disclaimerSecondaryColor: '#4791FF',
  disclaimerTextColor: '#000',
  messengerSenderColor: '#00A73E',
  messengerIncomerColor: "#707070",
  messengerSystemColor: "#2A313A",
  borderColor: '#AAAAAA',
  inputBg:'white',
  chatInputBorder: '',
  listItemHover: '',
  active: '',
  activeTabBorter: '#25D366',
  emptyMessagesFontColor: '#000'
}

type ThemeVariables = typeof defaultThemeVariables

type ThemeContextContent = {
  theme: Partial<ThemeVariables>
}

export const MessageThemeContext = createContext<ThemeContextContent>(
  {} as unknown as ThemeContextContent
)

type Props = {
  children: ReactNode
  theme?: Partial<ThemeVariables>
}
const MessageThemeWrapper = ({ children, theme = defaultThemeVariables }: Props) => {
  const config = {
    theme
  }

  return (
    <MessageThemeContext.Provider value={config}>
      {children}
    </MessageThemeContext.Provider>
  )
}

export default MessageThemeWrapper
