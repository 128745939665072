import React from 'react'

interface IProps {
    color:string
}
export default function TrashIcon({color} : IProps) {
  return (
    <svg width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 0.928571H9.75L8.82143 0H4.17857L3.25 0.928571H0V2.78571H13M0.928571 14.8571C0.928571 15.3497 1.12423 15.8221 1.47252 16.1703C1.8208 16.5186 2.29317 16.7143 2.78571 16.7143H10.2143C10.7068 16.7143 11.1792 16.5186 11.5275 16.1703C11.8758 15.8221 12.0714 15.3497 12.0714 14.8571V3.71429H0.928571V14.8571Z" fill={color}/>
    </svg>        

  )
}
