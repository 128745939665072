import React from 'react'
import useTheme from '../hooks/useThemes'

export default function EmptyIcon () {
  const {theme} = useTheme()
  return (
    <svg width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.4286 0H1.57143C0.939288 0 0.428574 0.510714 0.428574 1.14286V30.8571C0.428574 31.4893 0.939288 32 1.57143 32H24.4286C25.0607 32 25.5714 31.4893 25.5714 30.8571V1.14286C25.5714 0.510714 25.0607 0 24.4286 0ZM23 29.4286H3V22.25H6.49643C6.91072 23.4214 7.63929 24.475 8.60715 25.275C9.83929 26.2929 11.4 26.8571 13 26.8571C14.6 26.8571 16.1607 26.2964 17.3929 25.275C18.3607 24.475 19.0893 23.4214 19.5036 22.25H23V29.4286ZM23 20H17.7L17.5143 20.8821C17.0857 23.0179 15.1857 24.5714 13 24.5714C10.8143 24.5714 8.91429 23.0179 8.48215 20.8821L8.29643 20H3V2.57143H23V20Z" fill={theme.emptyMessagesFontColor}/>
    </svg>
  )
}
