import React from 'react'


export default function TimerIcon({ color }: { color: string }) {
  return (
    <svg width="14" height="14" viewBox="0 0 18 18" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="M9.42207 4.79883H8.57656C8.49922 4.79883 8.43594 4.86211 8.43594 4.93946V9.77871C8.43594 9.82442 8.45703 9.8666 8.49394 9.89297L11.3996 12.0147C11.4629 12.0604 11.5508 12.048 11.5965 11.9848L12.0992 11.2992C12.1467 11.2342 12.1326 11.1463 12.0693 11.1023L9.56269 9.29004V4.93946C9.56269 4.86211 9.49941 4.79883 9.42207 4.79883ZM13.2857 6.12071L16.042 6.79395C16.1299 6.81504 16.216 6.74824 16.216 6.6586L16.2301 3.81973C16.2301 3.70196 16.0947 3.63516 16.0033 3.70899L13.233 5.87285C13.2121 5.88905 13.1961 5.91083 13.187 5.93568C13.1779 5.96054 13.176 5.98746 13.1815 6.01335C13.187 6.03924 13.1997 6.06305 13.2182 6.08204C13.2366 6.10103 13.26 6.11444 13.2857 6.12071ZM16.2336 11.4135L15.2369 11.0707C15.2022 11.0588 15.1641 11.061 15.131 11.0767C15.0978 11.0925 15.0721 11.1206 15.0594 11.1551C15.026 11.2447 14.9908 11.3326 14.9539 11.4205C14.641 12.1605 14.1928 12.8268 13.6197 13.3981C13.053 13.9665 12.3815 14.4196 11.6422 14.7322C10.8764 15.056 10.0531 15.2222 9.22168 15.2209C8.38145 15.2209 7.56758 15.0574 6.80117 14.7322C6.06191 14.4196 5.39032 13.9665 4.82363 13.3981C4.25234 12.8268 3.8041 12.1605 3.48945 11.4205C3.16745 10.6542 3.00248 9.83117 3.0043 9C3.0043 8.15977 3.16777 7.34414 3.49297 6.57774C3.80586 5.8377 4.2541 5.17149 4.82715 4.6002C5.39383 4.03175 6.06542 3.57866 6.80469 3.26602C7.56758 2.94082 8.3832 2.77735 9.22344 2.77735C10.0637 2.77735 10.8775 2.94082 11.6439 3.26602C12.3832 3.57866 13.0548 4.03175 13.6215 4.6002C13.8008 4.78125 13.9695 4.96934 14.1242 5.16797L15.1754 4.34531C13.792 2.57696 11.6387 1.43965 9.21992 1.44141C5.0082 1.44317 1.62617 4.86387 1.66836 9.07735C1.71055 13.217 5.07676 16.5586 9.22344 16.5586C12.4842 16.5586 15.2615 14.4914 16.3197 11.5963C16.3461 11.5225 16.3074 11.4398 16.2336 11.4135Z" fill={color} />
    </svg>
  )
}
