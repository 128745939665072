import { useState } from "react"
import { IList } from 'types'

type _viewDesktop = 'empty' | 'messages' | 'newChat'
type _viewMobile = 'list' | 'messages'

export const useConversations = () => {
  const SLICE = 15
  const [sliceCount, setSliceCount] = useState(SLICE)
  const [conversations, setConversations] = useState<IList[]>([])
  const [currentChat, setCurrentChat] = useState<IList>()
  const [viewDesktop, setViewDesktop] = useState<_viewDesktop>('empty')
  const [viewMobile, setViewMobile] = useState<'list' | 'messages'>('list')

  const changeViewDesktop = (view: _viewDesktop) => {
    setViewDesktop(view)
  }

  const changeViewMobile= (view: _viewMobile) => {
    setViewMobile(view)
  }
  
  const selectChat = (chat: IList)=> {
    setCurrentChat(chat)
  }

  const onIntersect = () => {
    setSliceCount(prev => {
      const count = prev + SLICE
      return count
    })
  }

  const hasNext = () => {
    return conversations.length >= sliceCount
  }

  const resetConversations = () => {
    setConversations([])
  }

  const mapConversationsUnread = (prev: IList[]) => (id: string) => {
    return (prev || []).map(conversation => {
      if (conversation?._id === id) {
        return {
          ...conversation,
          totalUnreadMessages: 0
        }
      }

      return conversation
    })
  }
  
  const reorderConversations = (conversations: IList[], ticketId?: string ) => {
    const index = conversations.findIndex(conversation => conversation._id === ticketId)
    if (index !== -1) {
      const conversationToMove = conversations.splice(index, 1)[0]
      conversations.unshift(conversationToMove)
    }
  }

  const mapConversationsIncrementUnread = (prev: IList[], ticketId?: string) => (id: string) => {
    const updatedConversations = (prev || []).map(conversation => {
      if (conversation._id === id) {
        return {
          ...conversation,
          totalUnreadMessages: ticketId === id ? 0 : conversation.totalUnreadMessages + 1
        }
      }
      return conversation
    })
  
    reorderConversations(updatedConversations, ticketId)
  
    return updatedConversations
  }

  const setAsRead = (id: string) => {
    setConversations(prev => mapConversationsUnread(prev)(id)) 
  }


  const addConversations = (
    conversations: IList[], 
    goToNewConversation?: (chat: IList)=> void
  ) => {
    setConversations(conversations)

    if (goToNewConversation) goToNewConversation(conversations[0])
  
  }

  const addOneUnreadToTicket = (ticketId: string, currentChat?: string) => {
    setConversations(prev => {
      return mapConversationsIncrementUnread(prev, currentChat)(ticketId) }
    )
  }

  const removeClosedConversation = (ticketId: string) => {
    const filtred = conversations.filter(conversation => conversation._id !== ticketId)
    addConversations(filtred)
  } 

  const closeIfIsCurrent = (ticketId: string) => {
    if (ticketId === currentChat?._id) {
      changeViewDesktop('empty')
      changeViewMobile('list')
    }
  }

  const onCloseChat = (ticketId: string) => {
    removeClosedConversation(ticketId)
    closeIfIsCurrent(ticketId)
  }

  const reset = () => {
    setSliceCount(SLICE)
    resetConversations()
  }

  return {
    onIntersect,
    reset,
    conversations,
    hasNext,
    addConversations,
    addOneUnreadToTicket,
    setAsRead,
    sliceCount,
    currentChat,
    selectChat,
    onCloseChat,
    changeViewDesktop,
    viewDesktop,
    changeViewMobile,
    viewMobile
  }
}
