import React from 'react'
import useTheme from '../../hooks/useThemes'
import { IInconmingMessage } from 'types'

function SystemMessage ({ date, message, formatDate, id }: IInconmingMessage) {
  const {theme} = useTheme()

  return (
    <div 
      className="messenger__messages-row--system"
      id={id}
    >
      <div className="messenger__messages-received messenger__messages-received--system" style={{background: theme?.messengerSystemColor}}>
        <span>
          <p className="messenger__message">{message}</p>
          <p className="messenger__message--date">{formatDate(date) ?? new Date(date).toString()}</p>
        </span>
      </div>
    </div>
  )
}

export default SystemMessage
