import React from 'react'
import useTheme from '../../hooks/useThemes'

interface INewChatButton {
  removeActiveItem: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  messegerView: 'empty' | 'messages' | 'newChat',
  chatButtons: {
    label: string
    onClick: () => void
    type: string
    canSee: boolean
    disabled?: boolean
  }[]
}

function ChatButtons ({ chatButtons, removeActiveItem }: INewChatButton) {
  const { theme } = useTheme()

  const getButtonType = (type: string, disabled?: boolean) => {
    if (type === 'primary') return {
      background: disabled ? theme?.buttonsDisabled : theme?.buttonPrimary,
      color: theme?.buttonPrimaryText
    }

    if (type === 'outlined') return {
      background: 'transparent',
      color: theme?.asideFontColor,
      border: `${theme?.asideFontColor} 1px solid`
    }
    return
  }

  return (
    <>
      {
        chatButtons.map((button => {
          return (
            button.canSee &&
            <>
              <button
                key={`button-${button.label}`}
                className={
                  button.disabled ? 'messenger__chat-button messenger__chat-button--disabled'
                    : 'messenger__chat-button'
                }
                onClick={(event) => {
                  button.onClick()
                  removeActiveItem(event)
                }}
                style={
                  getButtonType(button.type, button.disabled)
                }>
                {button.label}
              </button>
            </>
          )
        }))
      }
    </>
  )
}

export default ChatButtons 
