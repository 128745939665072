import { useContext } from 'react'
import { MessageThemeContext } from '../components/MessengerThemeWrapper/MessengerThemeWrapper'

const useTheme = () => {
  const context = useContext(MessageThemeContext)

  if (context === undefined) {
    throw new Error("useTheme must be used within a ThemeProvider")
  }

  return context
}

export default useTheme
