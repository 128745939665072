import React from 'react'
import useTheme from '../../hooks/useThemes'
interface IProps {
  options: {
    label: string,
    value: string
  }[],
  label: string
  onChange: (value: string) => void
  name: string
  placeholder: string
  value: string
}

function SelectNew ({ options, name, placeholder, label, onChange, value  }: IProps) {
  const { theme } = useTheme()
  return (
    <div className="form-select">
      <label htmlFor={name} className="form-select__label"
        style={{ color: theme.newChatFormTexts}}
      >{label}</label >
      <select 
        name={name}
        placeholder={placeholder}
        className="form-select__input"
        onChange={(e) => onChange(e.target.value)}
        value={value}
        style={{
          backgroundColor: theme.inputBg,
          color: theme.newChatFormTexts
        }}
      >
        {options.map(motivo =>
          <option key={motivo.value} value={motivo.value}>{motivo.label}</option>
        )}
      </select>
    </div>
  )
}

export default SelectNew
