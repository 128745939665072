import React, { useEffect, useState } from 'react'
import { IImagesContainer } from 'types'

function ImagesContainer ({ file, onClose }: IImagesContainer) {
  const [b64, setB64] = useState<string>('')

  useEffect(() => {
    function getBase64 (file: File) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    }

    if (file) getBase64(file).then(base64 => setB64(base64 as string))
  }, [file])

  return (
    <div className="images-container">
      <div className="images-container__close">
        <button 
          className="images-container__close--icon"
          onClick={onClose}
        />
      </div>

      <div className="images-container__container">
        <img
          alt='arquivos enviados pelo chat agx'
          src={b64}
          className="images-container__image"
        />
      </div>
    </div>
  )
}

export default ImagesContainer
