import useTheme from '../../hooks/useThemes'
import React, { useCallback, useState } from 'react'
import { IMessagesBalloon } from "types"
import ReadIcon from "../../icons/ReadIcon"
import ReactSimpleImageViewer from '../../../../../node_modules/react-simple-image-viewer'
import { MessageImage } from '../IncomingMessage/IncomingMessage'

function SenderMessages ({ date, message, read, hasFile, file, formatDate, id }: IMessagesBalloon) {
  const [currentImage, setCurrentImage] = useState(0)
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const { theme } = useTheme()

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }, [])

  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }
  
  return (
    <div className="messenger__messages-row--sender" >
      <div 
        className="messenger__messages-sender"
        id={id}
        style={{ background: theme?.messengerSenderColor }}
      >
        <span>
          {hasFile && (
            <MessageImage 
              src={file?.location}
              onClick={() => openImageViewer(currentImage)} 
            />
          )}

          <p className="messenger__message">{message}</p>
          <div className="messenger__message--bottom">
            <p className="messenger__message--date">{formatDate(date) ?? new Date(date).toString()}</p>
            <ReadIcon fill={read ? '#00efff' : 'white'} />
          </div>
        </span>
      </div>

      {file && isViewerOpen && 
        <ReactSimpleImageViewer
          src={[file.location]}
          currentIndex={0}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      }
    </div>
  )
}

export default SenderMessages
