import useTheme from '../../hooks/useThemes'
import { activeItem } from '../../views/MessengerListItem'
import React from 'react'

interface IProps {
  handleChangeTickets: (tab: string) => void
  chatListTabs: { value: string, label: string }[]
}

function ChatTabs ({handleChangeTickets, chatListTabs }: IProps) {
  const { theme }= useTheme()
  return (
    <div className="chat-tabs" style={
      {color: theme.asideFontColor}
    }>
      {chatListTabs.map((tab, index )=> {return (
        <button
          key={`chatListTab-${index}`}
          className={`chat-tabs__button ${ index === 0 && 'chat-tabs__button--active'}`}
          onClick={(e) => {
            handleChangeTickets(tab.value)
            activeItem(e, 'chat-tabs__button', 'chat-tabs__button--active')
          }}
        >
          {tab.label}
        </button>
      )})}
    </div>
  )
}

export default ChatTabs
