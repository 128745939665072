import React, { ReactNode, CSSProperties } from 'react'

interface IInfiniteScroll {
  loading: boolean
  more: boolean
  fetch: () => void
  children: ReactNode
  loadingCover: React.ReactElement
  root: Element | null
  reverse?: boolean
  rootMargin?: number
}

const intersectDivStyle: CSSProperties = {
  width: 25,
  height: 25,
  visibility: 'hidden'
}

function InfiniteScroll ({ 
  loading,
  loadingCover,
  more,
  fetch,
  children,
  root,
  reverse = false,
  rootMargin = 400
}: IInfiniteScroll) {
  const [infiniteElement, setInfiniteElement] = React.useState<HTMLDivElement | null>(null)

  React.useEffect(() => {
    if (!root) return
    const currentElement = infiniteElement
    const observer = new IntersectionObserver(entries => {
      const first = entries[0]
      if (first.isIntersecting === true) fetch()
    }, {
      root,
      rootMargin: rootMargin.toString() + 'px',
      threshold: .5
    })

    if (currentElement) {
      observer.observe(currentElement)
    }

    return () => {
      if (currentElement) observer.unobserve(currentElement)
    }

  }, [infiniteElement])

  if (reverse) {
    return (
      <>
        {(more && !loading) && <div ref={setInfiniteElement} style={intersectDivStyle}></div>}
        {loading && loadingCover}
        {children}
      </>
    )
  }

  return (
    <>
      {children}
      {loading && loadingCover}
      {(more && !loading) && <div ref={setInfiniteElement} style={intersectDivStyle}></div>}
    </>
  )
}

export default InfiniteScroll
