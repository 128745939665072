import React, { Fragment, useState, useRef, MutableRefObject, useEffect } from 'react'
import ImagesContainer from '../components/ImagesContainer/ImagesContainer'
import MessageBalloon from '../components/MessageBallon/MessageBalloon'

import MessageIcon from '../icons/MessageIcon'

import { ICommonProps, IList, IMessages, IOption } from 'types'
import useTheme from '../hooks/useThemes'

import NewChatForm from './NewFormChat'
import EmptyIcon from '../icons/EmptyIcon'
import SendMessageForm from '../components/SendMessageForm/SendMessageForm'
import InfiniteScroll from '../components/InfiniteScroll/InfiniteScroll'

interface IScrollableContainer {
  loading: boolean
  onIntersect: () => Promise<void>
  hasNext: boolean
}

type MessengerView = 'empty' | 'messages' | 'newChat'

interface IMessengerMessages extends ICommonProps, IScrollableContainer {
  header: React.ReactElement
  current?: IList
  creatorId?: string
  allowImages: boolean
  tab?: 'list' | 'messages'
  messegerView: MessengerView,
  className?: string
  messages: IMessages[]
  onSubmit: (value: FormData, callback: () => void) => Promise<void>
  cancelNewChat: () => void
  formatDate: (date: string | Date) => string | undefined
  reasons: Array<IOption>
  products: Array<IOption>
  submitNewChat: (values: FormData) => void,
  newChatLoading: boolean
}

interface IMessengerForm {
  children: React.ReactNode,
  messegerView: MessengerView,
  reasons: Array<IOption>
  products: Array<IOption>
  submitNewChat: (values: FormData) => void,
  cancelNewChat: () => void,
  newChatLoading: boolean
}

function MessagesContainerView ({ 
  children,
  messegerView,
  submitNewChat,
  cancelNewChat,
  reasons,
  products,
  newChatLoading
}: IMessengerForm) {
  const { theme } = useTheme()

  if (messegerView === 'empty') {
    return (
      <div 
        className="messenger__messages-container--empty"
        style={{ backgroundColor: theme?.messengerNotSelectedBg }}
      >
        <MessageIcon width="72" height="72" />
        <p>Selecione uma conversa para visualizar as mensagens</p>
      </div>
    )
  }

  if (messegerView === 'newChat') {
    return (
      <NewChatForm 
        reasons={reasons}
        products={products}
        submitNewChat={submitNewChat}
        cancelNewChat={cancelNewChat}
        loading={newChatLoading}
      />
    )
  }

  return <>{children}</>
}

function SpinLoading () {
  return (
    <div className="messenger__messages-loading">
      <div className="messenger__messages-loading--loader" />
    </div>
  )
}

function Messages ({ messages, creatorId, formatDate, loading }: { 
  messages: IMessages[], 
  creatorId?: string,
  formatDate: (date: string | Date) => string | undefined,
  loading: boolean
}) {
  const { theme } = useTheme()

  if (!messages || messages.length === 0 && !loading) {
    return (
      <div className="messenger__message-empty"
        style={{color: theme.emptyMessagesFontColor}}
      >
        <EmptyIcon />
        <p>Nenhuma mensagem encontrada</p>
      </div>
    )
  }

  return (
    <>
      {messages.map((item, idx) => {
        return (
          <Fragment key={`${item.ticketId}_${item.senderId}_${idx}`}>
            <MessageBalloon
              id={`message_${idx}`}
              formatDate={formatDate}
              creatorId={creatorId}
              item={item}
            />
          </Fragment>
        )
      })}
    </>
  )
}

const onScrollToBottom = (scrollContainer: MutableRefObject<HTMLDivElement | null>) => {
  if (scrollContainer.current) {
    scrollContainer.current.scrollTop = Number.MAX_SAFE_INTEGER
  }
}

function MessagesContainer ({
  children,
  fowardRef,
  ...rest
}: { 
  children: React.ReactNode,
  fowardRef: MutableRefObject<HTMLDivElement | null>
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  const { theme } = useTheme()

  return (
    <div 
      {...rest}
      ref={fowardRef}
      className="messenger__messages-container"
      style={{ 
        padding: '1rem',
        backgroundColor: theme?.messengerMessagesBg ,
        border: `1px solid ${theme.borderColor}`
      }}
    >
      {children}
    </div>
  )
}

function ScrollableContainer ({ 
  children,
  loading,
  onIntersect,
  hasNext,
  scrollContainerFowardRef
}: {
  children: React.ReactNode,
  scrollContainerFowardRef: MutableRefObject<HTMLDivElement | null>
} & IScrollableContainer) {
  const intersetRootMargin = 300

  const getElementScrollHeight = (divRef: HTMLDivElement | null) => {
    if (!divRef) return null
    return divRef.scrollHeight
  }

  const getElementScrollTop = (divRef: HTMLDivElement | null) => {
    if (!divRef) return null
    return divRef.scrollTop
  }

  const calculateScrollToDecrease = (
    elementBeforeIntersetHeight: number,
    elementAfterIntersectHeight: number,
    intersetRootMargin: number
  ) => {
    return Math.abs(elementAfterIntersectHeight - elementBeforeIntersetHeight) + intersetRootMargin 
  }

  const isNill = (value: number | null | undefined) => {
    return value === null && value === undefined
  }

  const onFetchInfiniteScroll = async () => {
    const elementBeforeIntersetHeight = getElementScrollHeight(scrollContainerFowardRef.current)

    await onIntersect()

    const elementAfterIntersectHeight = getElementScrollHeight(scrollContainerFowardRef.current)

    if (
      scrollContainerFowardRef.current &&
      !isNill(elementBeforeIntersetHeight) &&
      !isNill(elementAfterIntersectHeight)
    ) {
      const currentScrollTop = getElementScrollTop(scrollContainerFowardRef.current) || 0
      scrollContainerFowardRef.current.scrollTop = calculateScrollToDecrease(
        elementAfterIntersectHeight as number,
        elementBeforeIntersetHeight as number,
        currentScrollTop 
      )
    }
  }

  useEffect(() => {
    onScrollToBottom(scrollContainerFowardRef)
  }, [])

  return (
    <MessagesContainer 
      fowardRef={scrollContainerFowardRef}
    >
      <InfiniteScroll
        root={document.querySelector('.messenger__messages-container')}
        loading={loading}
        loadingCover={<SpinLoading />}
        more={hasNext}
        fetch={onFetchInfiniteScroll}
        rootMargin={intersetRootMargin} 
        reverse
      >
        {children}
      </InfiniteScroll>
    </MessagesContainer>
  )
}

function MessengerMessages ({
  header,
  tab,
  className = '',
  current,
  messages,
  allowImages,
  creatorId,
  loading,
  onSubmit,
  submitNewChat,
  cancelNewChat,
  reasons,
  products,
  messegerView,
  formatDate,
  hasNext,
  onIntersect,
  newChatLoading
}: IMessengerMessages) {
  const [file, setFile] = useState<File | null>()
  const scrollContainerRef = useRef<HTMLDivElement | null>(null)

  const { theme } = useTheme()

  const styleClassnames = {
    mobile: tab === 'list' ? 'messenger__mobile-hide' : '' 
  }

  const updateFile = (file: File | null) => {
    setFile(file)
  }

  const onSubmitNewMessage = async (message: FormData, callback: () => void) => {
    await onSubmit(message, callback)
    onScrollToBottom(scrollContainerRef)
  }

  return (
    <div className={`messenger__messages ${className} ${styleClassnames.mobile} `}>
      <MessagesContainerView
        newChatLoading={newChatLoading}
        reasons={reasons}
        products={products}
        submitNewChat={submitNewChat}
        cancelNewChat={cancelNewChat}
        messegerView={messegerView}
      >
        {file ? (
          <ImagesContainer 
            file={file}
            onClose={() => setFile(null)} 
          />
        ) : (
          <>
            <div
              className="messenger__messages-header"
              style={{
                backgroundColor: theme?.headerAndSenderBg,
                border: `1px solid ${theme.borderColor}`
              }}
            >
              {React.cloneElement(header)}
            </div>
              
            {loading && !messages.length ? (
              <MessagesContainer fowardRef={scrollContainerRef}>
                <SpinLoading />
              </MessagesContainer>
            ) : (
              <ScrollableContainer
                scrollContainerFowardRef={scrollContainerRef}
                loading={loading}
                hasNext={hasNext}
                onIntersect={onIntersect}
              >
                <Messages 
                  formatDate={formatDate}
                  messages={messages}
                  creatorId={creatorId}
                  loading={loading}
                />
              </ScrollableContainer>
            )}
          </>
        )}

        <div
          className="messenger__messages-send"
          style={{ 
            backgroundColor: theme?.headerAndSenderBg,
            border: `1px solid ${theme.borderColor}`,
          }}
        >
          <SendMessageForm 
            allowImages={allowImages} 
            onSubmit={onSubmitNewMessage} 
            file={file}  
            updateFile={updateFile} 
            current={current}
          />
        </div>
      </MessagesContainerView>
    </div>
  )
}

export default MessengerMessages
