import useTheme from '../../hooks/useThemes'
import React, { useCallback, useState } from 'react'
import { IInconmingMessage } from 'types'
import MessengerAvatar from '../MessengerAvatar/MessengerAvatar'
import ReactSimpleImageViewer from '../../../../../node_modules/react-simple-image-viewer'

export const MessageImage = ({ ...rest }) => {
  return (
    <div className="messenger__message--file">
      <img 
        {...rest}
        width={200}
        height={200}
        alt="imagem na mensagem"
      />
    </div>
  )
}

function IncomingMessage ({ date, message, user, hasFile, file, isSystemMessage, formatDate, id }: IInconmingMessage) {
  const {theme} = useTheme()
  const [currentImage, setCurrentImage] = useState(0)
  const [isViewerOpen, setIsViewerOpen] = useState(false)

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }, [])

  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }

  return (
    <div 
      className="messenger__messages-row--received"
      id={id}
    >
      {(user.avatar || user.name) && !isSystemMessage && (
        <div className="messenger__message-avatar mr-2">
          <MessengerAvatar image={user.avatar}>{user.name.slice(0, 1)}</MessengerAvatar>
        </div>
      )}

      <div className="messenger__messages-received" style={{background: theme?.messengerIncomerColor}}>
        {!isSystemMessage && <p className="messenger__message-title">{user.name}</p>}

        <span>
          {hasFile && (
            <MessageImage
              src={file?.location}
              onClick={() => openImageViewer(currentImage)}
            />
          )}

          <p className="messenger__message">{message}</p>
          <p className="messenger__message--date">{formatDate(date) ?? new Date(date).toString()}</p>
        </span>
        
      </div>
      {file && isViewerOpen && 
        <ReactSimpleImageViewer
          src={[file.location]}
          currentIndex={0}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      }
    </div>
  )
}

export default IncomingMessage
