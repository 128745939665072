import React from 'react'
import useTheme from '../hooks/useThemes'

export default function CloseIcon() {
  const {theme} = useTheme()
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.4519 10.9583L20.435 3.9751C20.7664 3.64426 20.9529 3.19532 20.9533 2.72704C20.9537 2.25875 20.7681 1.80949 20.4372 1.47807C20.1064 1.14665 19.6574 0.960225 19.1892 0.959812C18.7209 0.959398 18.2716 1.14503 17.9402 1.47586L10.957 8.45902L3.97388 1.47586C3.64246 1.14444 3.19296 0.958252 2.72426 0.958252C2.25556 0.958252 1.80606 1.14444 1.47464 1.47586C1.14322 1.80728 0.957031 2.25678 0.957031 2.72548C0.957031 3.19418 1.14322 3.64368 1.47464 3.9751L8.4578 10.9583L1.47464 17.9414C1.14322 18.2728 0.957031 18.7223 0.957031 19.191C0.957031 19.6597 1.14322 20.1092 1.47464 20.4406C1.80606 20.7721 2.25556 20.9583 2.72426 20.9583C3.19296 20.9583 3.64246 20.7721 3.97388 20.4406L10.957 13.4575L17.9402 20.4406C18.2716 20.7721 18.7211 20.9583 19.1898 20.9583C19.6585 20.9583 20.108 20.7721 20.4394 20.4406C20.7708 20.1092 20.957 19.6597 20.957 19.191C20.957 18.7223 20.7708 18.2728 20.4394 17.9414L13.4519 10.9583Z" fill={theme.newChatFormTexts ?? '#476077'} />
    </svg>
  )
}
