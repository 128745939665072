import React from 'react'
import useTheme from '../hooks/useThemes'

export default function AttachFileIcon ({ ...rest }) {
  const { theme } = useTheme()
  return (
    <svg width="26" height="28" viewBox="0 0 26 28"xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M22.5464 2.73571C19.1821 -0.628572 13.7036 -0.628572 10.3429 2.73571L1.02143 12.05C0.960715 12.1107 0.928572 12.1929 0.928572 12.2786C0.928572 12.3643 0.960715 12.4464 1.02143 12.5071L2.33929 13.825C2.39953 13.885 2.48107 13.9186 2.56607 13.9186C2.65108 13.9186 2.73262 13.885 2.79286 13.825L12.1143 4.51071C13.2714 3.35357 14.8107 2.71786 16.4464 2.71786C18.0821 2.71786 19.6214 3.35357 20.775 4.51071C21.9321 5.66786 22.5679 7.20714 22.5679 8.83929C22.5679 10.475 21.9321 12.0107 20.775 13.1679L11.275 22.6643L9.73572 24.2036C8.29643 25.6429 5.95714 25.6429 4.51786 24.2036C3.82143 23.5071 3.43929 22.5821 3.43929 21.5964C3.43929 20.6107 3.82143 19.6857 4.51786 18.9893L13.9429 9.56786C14.1821 9.33214 14.4964 9.2 14.8321 9.2H14.8357C15.1714 9.2 15.4821 9.33214 15.7179 9.56786C15.9571 9.80714 16.0857 10.1214 16.0857 10.4571C16.0857 10.7893 15.9536 11.1036 15.7179 11.3393L8.01429 19.0357C7.95357 19.0964 7.92143 19.1786 7.92143 19.2643C7.92143 19.35 7.95357 19.4321 8.01429 19.4929L9.33214 20.8107C9.39238 20.8707 9.47393 20.9044 9.55893 20.9044C9.64393 20.9044 9.72548 20.8707 9.78572 20.8107L17.4857 13.1107C18.1964 12.4 18.5857 11.4571 18.5857 10.4536C18.5857 9.45 18.1929 8.50357 17.4857 7.79643C16.0179 6.32857 13.6321 6.33214 12.1643 7.79643L11.25 8.71429L2.74286 17.2179C2.16547 17.7919 1.70778 18.4748 1.39634 19.227C1.0849 19.9793 0.925905 20.7858 0.928572 21.6C0.928572 23.2536 1.575 24.8071 2.74286 25.975C3.95357 27.1821 5.53929 27.7857 7.125 27.7857C8.71072 27.7857 10.2964 27.1821 11.5036 25.975L22.5464 14.9393C24.1714 13.3107 25.0714 11.1429 25.0714 8.83929C25.075 6.53214 24.175 4.36429 22.5464 2.73571Z"  fill={ theme.uploadFileIconColor} />
    </svg>
  )
}
