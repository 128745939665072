import useTheme from '../../hooks/useThemes'
import React from 'react'
import { IMesssengerAvatar } from 'types'

function MessengerAvatar ({ image, children, className = '' }: IMesssengerAvatar) {
  const { theme } = useTheme()
  return (
    <figure
      className={`${className} messenger__aside-list-item--avatar`}
      style={{
        background: theme.messengerIncomerColor
      }}
    >
      {image
        ? (<img loading="lazy" alt='imagem de um avatar de usuário' src={image} />)
        : children
      }
    </figure>
  )
}

export default MessengerAvatar
